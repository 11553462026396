<template>
  <div class="errorprone">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="errorprone-main">
      <div class="left" v-loading="loading">
        <div class="count-num" v-show="mainData.id && questionList.length">
          <div>本月练习数量: {{ questionList[questionIndex]?.train_count }}</div>
          <div>本月错题数量: {{ questionList[questionIndex]?.wrong_count }}</div>
          <div>计算得分: {{ questionList[questionIndex]?.score }}</div>
        </div>
        <div v-if="mainData.id && questionList.length">
          <EditData :allData="{ data: mainData, allCategory: allCategoryList }" :statusObj="statusObj"
            :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="changeData"></EditData>
        </div>
        <div v-else-if="!loading && !questionList.length" class="empty">
          <img src="../../assets/images/new_icon/empty.png" alt="">
          <div>暂无试题数据</div>
        </div>
      </div>
      <div class="right">
        <div class="tablist">
          <div class="tab-item" :class="searchForm.status === '0' ? 'tab-active' : ''" @click="changeTab('0')">待处理</div>
          <div class="tab-item" :class="searchForm.status === '1' ? 'tab-active' : ''" @click="changeTab('1')">已处理</div>
        </div>
        <div class="search-box">
          <div class="search">
            <Input v-model="searchForm.keyword" :placeholder="$t('trainingcamp_exam_feedback_search')" clearable search
              :enter-button="true" @on-enter="getList()" @on-search="getList()" />
          </div>
          <div class="category">
            <div class="category-wrap">
              <p>一级分类:</p>
              <Select v-model="searchForm.category_id" clearable style="margin-right: 10px"
                @on-change="(data) => { changeCategory(data, 'categoryId') }">
                <Option v-for="item in categoryFirstList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <div class="category-wrap">
              <p>二级分类:</p>
              <Select v-model="searchForm.category_second_id" clearable style="margin-right: 10px"
                @on-change="(data) => { changeCategory(data, 'secondCategoryId') }">
                <Option v-for="item in categorySecondList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
          <div class="category">
            <div class="category-wrap">
              <p>三级分类:</p>
              <Select v-model="searchForm.category_third_id" clearable
                @on-change="(data) => { changeCategory(data, 'threeCategoryId') }">
                <Option v-for="item in categoryThirdList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
          <div class="category">
            <div class="category-wrap">
              <p>月份筛选:</p>
              <el-date-picker
                style="width: 100%;"
                v-model="searchForm.month"
                type="month"
                size="small"
                value-format="timestamp"
                placeholder="选择月份筛选"
                @change="getList()">
              </el-date-picker>
            </div>
            <div class="category-wrap">
              <p>练习数大于:</p>
              <el-input size="small" @blur="getList()" v-model.number="searchForm.train_count" placeholder="输入练习数量筛选"></el-input>
            </div>
          </div> 
        </div>
        <div class="question-list">
          <div class="list-title">
            <div class="left">
              <div class="title-text">易错题列表</div>
              <Tooltip style="font-size: 14px; margin-right: 10px;">
                <Icon type="ios-help-circle-outline" />
                <div slot="content">
                  <p>白色：未处理</p>
                  <p>蓝色：当前</p>
                  <p>绿色：已处理</p>
                </div>
              </Tooltip>
              <div class="count">剩余<span>{{ pagination.total }}</span>题</div>
            </div>
            <el-button type="text" @click="refresh()">刷新列表</el-button>
          </div>
          <div class="list-content">
            <div class="list-item" :class="handleColor(item,index)"
              v-for="(item, index) in questionList" :key="item.id" @click="getQuestionDetail(index)">{{ (pagination.page -
                1) * pagination.pageSize + index + 1 }}</div>
          </div>
          <Pagin class="pagin" :pagination="pagination" @currentChange="currentChange" @sizeChange="sizeChange" />
        </div>
        <div class="btn-group">
          <el-button size="small" @click="prev()">上一题</el-button>
          <el-button size="small" @click="next()">下一题</el-button>
          <el-button size="small" type="primary" @click="submit()">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagin from "@/components/myPaginSimple.vue";
import EditData from './components/data/editData'
export default {
  name: "errorProneQuestionList",
  components: {
    Pagin,
    EditData
  },
  data() {
    return {
      searchForm: {
        // 一分
        category_id: "",
        // 二分
        category_second_id: "",
        // 三分
        category_third_id: "",
        keyword: "",
        status: "0",
        // 月份
        month:"",
        train_count:""
      },
      pagination: {
        page: 1,
        pageSize: 50,
        total: 0
      },
      questionList: [],
      categoryFirstList: [],
      categorySecondList: [],
      categoryThirdList: [],
      questionIndex: 0,
      loading: true,


      // 组件所需要数据
      mainData: {},
      allCategoryList: [],
      statusObj: {},
      levelObj: {},
      typesList: [],
      sourceFroms: {}
    }
  },
  methods: {
    refresh(){
      const { pagination }=this;
      pagination.page=1;
      this.getList();
    },
    getList() {
      const { searchForm, pagination } = this;
      this.loading = true;
      const params = {
        page: pagination.page,
        page_size: pagination.pageSize,
        keyword: searchForm.keyword,
        status: searchForm.status,
        month:searchForm.month?(searchForm.month / 1000):null,
        train_count:searchForm.train_count
      }
      if (searchForm.category_id && searchForm.category_id !== "-1") {
        params.category_id = searchForm.category_id;
      }
      if (searchForm.category_second_id && searchForm.category_second_id !== "-1") {
        params.category_id = searchForm.category_second_id;
      }
      if (searchForm.category_third_id && searchForm.category_third_id !== "-1") {
        params.category_id = searchForm.category_third_id;
      }
      this.api.dataset.errorProneQuestionList(params).then(res => {
        pagination.total = Number(res.count);
        res?.list?.forEach(item=>{
          item.isHandle=false;
        });
        this.questionList = res.list || [];
        this.questionIndex = 0;
        this.getQuestionDetail(0);
      });
    },
    getQuestionDetail(index) {
      const { questionList } = this;
      this.loading = true;

      if(!questionList[index]){
        this.loading=false;
        return;
      }

      this.questionIndex = index;
      this.api.dataset.dataDetail({
        id: questionList[index].data_id
      }).then(res => {
        this.statusObj = res.statuses;
        this.levelObj = res.levels;
        this.sourceFroms = res.source_froms;

        this.allCategoryList = [];
        for (let name in res.info.categoryList) {
          let names = res.info.categoryList[name].map(sItem => {
            return sItem.name + '(id:' + sItem.id + ')'
          }).join('-');
          this.allCategoryList.push({
            name: names,
            value: name
          });
        }
        console.log("分类详情2222",this.allCategoryList);

        this.mainData = res.info;
        this.mainData.labelKeywords = this.mainData.labelKeywords.map((item) => {
          return item.label_id;
        });
        this.mainData.labelKeypoints = this.mainData.labelKeypoints.map((item) => {
          return item.label_id;
        });
        this.$set(this.mainData, 'categoryIds', []);
        for (let name in this.mainData.categoryList) {
          this.mainData.categoryIds.push(name)
        }

        this.typesList = [];
        for (let name in res.types) {
          this.typesList.push({
            name: res.types[name],
            value: name
          })
        }
        this.loading = false;
      });
    },
    prev() {
      if (this.questionIndex === 0) {
        this.$Message.info("已经是第一题了");
        return;
      }
      this.questionIndex--;
      this.getQuestionDetail(this.questionIndex);
    },
    next() {
      const { pagination } = this;
      if ((pagination.page - 1) * pagination.pageSize + this.questionIndex >= pagination.total-1) {
        this.$Message.info("已经是最后一题了");
        return;
      }
      this.questionIndex++;
      this.getQuestionDetail(this.questionIndex);
    },
    // 保存试题接口
    saveQuestion(){
      console.warn("保存试题");
      const { mainData:data }=this;
      if(!data.id){
        this.$Message.warning('请选择数据');
        return;
      }
      if(!data.categoryIds.length){
        this.$Message.warning('请选择分类');
        return;
      }
      if(!data.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }
      if(data.type == '1' || data.type == '2' || data.type == '3' || data.type == '4'){
        if(!data.options.length){ //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          return;
        }
        for(let i=0;i<data.options.length;i++){
          if(!data.options[i].name){
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            return;
          }
        }
      }else{
        data.options = [];
      }
      if(data.type == '1' || data.type == '2' || data.type == '3'){
        let len = data.options.filter((item)=>{
          return item.is_correct == '1';
        }).length
        if(!len){ //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          return;
        }
        if(data.type == '1' || data.type == '3'){
          if(len > 1){
            this.$Message.warning('只能选择一个正确答案');
            return;
          }
        }
      }

      const params = {
        id:data.id,
        name:data.name,
        explain:data.explain,
        options:JSON.stringify(data.options),
        type:data.type,
        level:data.level,
        status:data.status,
        source:data.source,
        label_keyword_ids:data.labelKeywords.join(','),
        label_keypoint_ids:data.labelKeypoints.join(','),
        category_ids:data.categoryIds.join('|'),
        site_id:'-1',
      };

      return new Promise((resolve,reject)=>{
        this.api.dataset.dataUpdate(params).then(()=>{
          resolve();
        }).catch(()=>{
          reject();
        });
      });
    },
    // 处理试题接口
    handleQuestion(){
      const { questionList, questionIndex }=this;
      return new Promise((resolve,reject)=>{
        this.api.dataset.errorProneHandle({
          id:questionList[questionIndex].id,
          status:"1"
        }).then(()=>{
          resolve();
        }).catch(()=>{
          reject();
        });
      });
    },
    submit(){
      const { questionList,questionIndex }=this;
      Promise.all([this.saveQuestion(),this.handleQuestion()]).then(()=>{
        this.$Message.success("处理成功");
        questionList[questionIndex].isHandle=true;
        this.next();
      });
    },
    handleColor(item,index){
      const { questionIndex }=this;
      let str="";
      if(item.isHandle){
        str+="item-handle";
      }
      if(index === questionIndex){
        str+="item-acitve";
      }
      return str;
    },
    changeData(data) {
      this.mainData = JSON.parse(JSON.stringify(data));
    },
    getCagegoryList() {
      const { searchForm } = this;
      this.api.dataset.categoryForm({
        site_id: "-1",
        category_id: searchForm.category_id,
        category_second_id: searchForm.category_second_id,
      }).then(res => {
        res?.categoryFirstList?.unshift({ id: "-1", name: "全部" });
        this.categoryFirstList = res.categoryFirstList || [];
        res?.categorySecondList?.unshift({ id: "-1", name: "全部" });
        this.categorySecondList = res.categorySecondList || [];
        res?.categoryThirdList?.unshift({ id: "-1", name: "全部" });
        this.categoryThirdList = res.categoryThirdList || [];
      });
    },
    changeTab(id) {
      const { searchForm } = this;
      searchForm.status = id;
      this.getList();
    },
    changeCategory() {
      this.getList();
      this.getCagegoryList();
    },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getList();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      this.getList();
    }
  },
  created() {
    this.getList();
    this.getCagegoryList();
  }
}
</script>

<style lang="scss" scoped>
.errorprone {
  padding: 10px;

  .errorprone-main {
    padding: 10px;
    display: flex;
    height: calc(100vh - 120px);
    overflow-y: auto;
    position: relative;

    .left {
      flex-grow: 1;
      background-color: white;
      width: 50%;
      border-radius: 4px;
      .count-num{
        padding: 40px 0 0 20px;
        display: flex;
        >div{
          font-size: 14px;
          font-weight: bold;
          color: #333;
          margin-right: 20px;
        }
      }
      .empty{
        margin-top: 30%;
        >img{
          width: 180px;
          height: 180px;
        }
        text-align: center;
      }
    }

    .right {
      width: 30%;
      min-width: 330px;
      flex-shrink: 0;
      padding: 0 10px;
      position: sticky;
      height: 100%;
      overflow-y: auto;
      top: 0;

      .tablist {
        display: flex;
        font-size: 16px;
        height: 38px;

        .tab-item {
          flex-grow: 1;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          cursor: pointer;

          &:nth-child(1) {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &:nth-last-child(1) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        .tab-active {
          background-color: #2d8cf0;
          color: white;
        }
      }

      .search-box {
        margin-top: 20px;

        .category {
          display: flex;
          margin-top: 20px;

          .category-wrap {
            flex-grow: 1;
            width: 50%;

            &:nth-child(1) {
              margin-right: 10px;
            }

            &:nth-last-child(1) {
              margin-right: 0;
            }

            >p {
              margin-bottom: 5px;
            }
          }
        }
      }

      .question-list {
        background-color: white;
        border-radius: 4px;
        margin-top: 20px;
        padding-bottom: 10px;

        .list-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;
          border-bottom: 1px solid #dcdee2;
          .left{
            display: flex;
            align-items: center;
          }

          .title-text {
            font-size: 20px;
            font-weight: bold;
          }

          .count {
            font-size: 14px;
            margin-right: 10px;

            >span {
              color: #2d8cf0;
            }
          }
          .el-button{
            font-size: 12px;
          }
        }

        .list-content {
          padding: 10px;
          padding-right: 0;
          display: flex;
          flex-wrap: wrap;

          .list-item {
            width: 50px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #dcdee2;
            border-radius: 4px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .item-handle{
            background-color: #19be6b;
            color:white;
          }

          .item-acitve {
            background-color: #2d8cf0;
            color: white;
          }
        }
      }

      .btn-group {
        margin-top: 10px;
      }
    }
  }
}
</style>