<template>
  <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="pagination.page"
    :page-size="pagination.pageSize" layout="prev, pager, next" background small
    :total="pagination.total">
  </el-pagination>
</template>
<script setup>
// 定义props
defineProps({
  pagination: {
    type: Object,
    default: () => {
      return {
        page: 1,
        pageSize: 10,
        total: 0
      }
    }
  }
});
// 定义emit
const emit = defineEmits(["currentChange", "sizeChange"]);
// 当前页改变
const currentChange = (current) => {
  emit("currentChange", current);
};
// 当前条数改变
const sizeChange = (size) => {
  emit("sizeChange", size);
}
</script>