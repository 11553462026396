<template>
  <div class="data">
    <div class="data-cont">
      <div class="data-type">
        <span class="data-type-name">ID:</span>
        <div class="data-type-list" style="font-weight: bold;">
          {{ dataObj.id }}
        </div>
      </div>
      <div class="data-type">
        <span class="data-type-name">分类:</span>
        <div class="data-type-list" style="flex: 1;width: 0;">
          <div class="data-type-list-item" :class="item.status ? 'active' : ''" v-for="item in categoryList"
            :key="item.id" @click="changeCategory(item)">
            <img v-if="!item.status" src="../../../../assets/images/new_icon/checkbox.png" width="18" height="18" />
            <img v-else src="../../../../assets/images/new_icon/select_checkbox.png" width="18" height="18">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="data-type">
        <span class="data-type-name">状态:</span>
        <div class="data-type-list-radio">
          <div class="data-type-list-radio-item" :class="dataObj.status == index ? 'active' : ''"
            v-for="(item, index) in statusObj" :key="index" @click="changeRadio('status', index)">
            <img v-if="dataObj.status != index" src="../../../../assets/images/new_icon/radio.png" width="16"
              height="16" />
            <img v-else src="../../../../assets/images/new_icon/select_radio.png" width="16" height="16">
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="data-type">
        <span class="data-type-name">难度:</span>
        <div class="data-type-list-radio">
          <div class="data-type-list-radio-item" :class="dataObj.level == index ? 'active' : ''"
            v-for="(item, index) in levelObj" :key="index" @click="changeRadio('level', index)">
            <img v-if="dataObj.level != index" src="../../../../assets/images/new_icon/radio.png" width="16"
              height="16" />
            <img v-else src="../../../../assets/images/new_icon/select_radio.png" width="16" height="16">
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="data-type">
        <span class="data-type-name">关键词:</span>
        <div class="data-type-list">
          <el-select v-model="dataObj.labelKeywords" multiple filterable remote allow-create default-first-option
            :remote-method="(query) => remoteMethod(query, 'label')" :loading="labelLoading" style="width:100%;"
            @change="changeLabel" placeholder="点击输入框选择">
            <el-option v-for="item in labelList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      
      <div class="data-type">
        <span class="data-type-name">考点:</span>
        <div class="data-type-list">
          <el-select v-model="dataObj.labelKeypoints" multiple filterable remote allow-create default-first-option
            :remote-method="(query) => remoteMethod(query, 'labelKeyPoint')" :loading="labelKeypointsLoading"
            style="width:100%;" @change="changeLabelKeypoints" placeholder="点击输入框选择">
            <el-option v-for="item in labelKeypointsList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="data-type">
        <span class="data-type-name">短标题:</span>
        <div class="data-type-list">
          <el-input v-model="dataObj.title" @input="subTitleChange" placeholder="请输入短标题"></el-input>
        </div>
      </div>

      <div class="data-type">
        <span class="data-type-name">考试来源:</span>
        <div class="data-type-list-radio">
          <div class="data-type-list-radio-item" style="color:#999999;">
            【选填项】
          </div>
          <div class="data-type-list-radio-item" :class="dataObj.source == index ? 'active' : ''"
            v-for="(item, index) in sourceFroms" :key="index" @click="changeRadio('source', index)">
            <img v-if="dataObj.source != index" src="../../../../assets/images/new_icon/radio.png" width="16"
              height="16" />
            <img v-else src="../../../../assets/images/new_icon/select_radio.png" width="16" height="16">
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="data-type">
        <span class="data-type-name">{{ $t('trainingcamp_train_correction_type') }}:</span>
        <div class="data-type-list-radio">
          <div class="data-type-list-radio-item" :class="dataObj.type == item.value ? 'active' : ''"
            v-for="(item, index) in typesList" :key="index" @click="changeRadio('type', item.value)">
            <img v-if="dataObj.type != item.value" src="../../../../assets/images/new_icon/radio.png" width="16"
              height="16" />
            <img v-else src="../../../../assets/images/new_icon/select_radio.png" width="16" height="16">
            <p>{{ item.name }}</p>
          </div>
        </div>
        <!--      <RadioGroup v-model="dataObj.type" size="large" style="margin-left: 20px">-->
        <!--        <Radio v-for="item in typesList" :label="item.value" :key="item.value">{{item.name}}</Radio>-->
        <!--      </RadioGroup>-->
      </div>
      <div class="data-type">
        <span class="data-type-name">{{ $t('trainingcamp_train_correction_title') }}:</span>
        <div class="data-type-list">
          <Richtxt :height="150" :dataObj="{ val: dataObj && dataObj.name }" key="feedback"
            @saveData="(data) => saveCurData(data, dataObj, 'name')"></Richtxt>
        </div>
      </div>
      <div class="data-type" v-for="(item, index) in dataObj.options" :key="index">
        <template v-if="dataObj.type == '1' || dataObj.type == '2' || dataObj.type == '3' || dataObj.type == '4'">
          <span class="data-type-name">{{ dataObj.type == '4' ? '答案' : '选项' }}{{ optionNameList[index] }}:</span>
          <div class="data-type-list">
            <div class="data-type-list-top">
              <div class="data-correct" v-if="dataObj.type != '4'" :class="item.is_correct == '1' ? 'active' : ''"
                @click="changeCorrect(item, dataObj)">{{ item.is_correct == '1' ?
                  $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct') }}</div>
              <div v-if="dataObj.type == '4'"></div>
              <div class="data-dele" v-if="dataObj.options.length > 1" @click="deleOption(index)">
                <img src="../../../../assets/images/new_icon/delete_icon.png" width="24" height="24">
              </div>
            </div>
            <Input v-if="dataObj.type == '4'" v-model="item.name" type="textarea" placeholder="请输入答案"
              @on-change="saveCurData(item.name, item, 'name')" />
            <Richtxt v-else :dataObj="{ val: item.name }" :height="150" @saveData="(data) => saveCurData(data, item, 'name')">
            </Richtxt>
          </div>
        </template>
      </div>
      <!--      <div class="data-tit">-->
      <!--        <div class="data-left">-->
      <!--          <p class="data-data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>-->
      <!--        </div>-->
      <!--        <div class="data-right">-->
      <!--          <Richtxt :height="150"  :dataObj="{val:dataObj && dataObj.name}" key="feedback" @saveData="(data)=>saveCurData(data,dataObj,'name')"></Richtxt>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="data-option-item" v-for="(item,index) in dataObj.options" :key="index">-->
      <!--        <div class="data-left">-->
      <!--          <p class="data-data-tit">{{optionNameList[index]}}</p>-->
      <!--          <p class="data-correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item,dataObj)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>-->
      <!--          <p class="data-dele" v-if="dataObj.options.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>-->
      <!--        </div>-->
      <!--        <div class="data-right">-->
      <!--          <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <Button type="primary" @click="addOption(dataObj.options)">{{ $t('trainingcamp_train_correction_add_option') }}</Button>-->
      <div class="add-option-btn" @click="addOption(dataObj.options)"
        v-if="dataObj.type == '1' || dataObj.type == '2' || dataObj.type == '3' || dataObj.type == '4'">
        {{ dataObj.type == '4' ? '添加答案' : $t('trainingcamp_train_correction_add_option') }}
      </div>
      <div class="data-type">
        <span class="data-type-name">{{ $t('trainingcamp_train_correction_analysis') }}:</span>
        <div class="data-type-list">
          <Richtxt :height="150" :dataObj="{ val: dataObj && dataObj.explain }"
            @saveData="(data) => saveCurData(data, dataObj, 'explain')"></Richtxt>
        </div>
      </div>
      <!--      <div class="data-analysis">-->
      <!--        <div class="data-left">-->
      <!--          <p class="data-data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>-->
      <!--        </div>-->
      <!--        <div class="data-right">-->
      <!--          <Richtxt :height="150" :dataObj="{val:dataObj && dataObj.explain}" @saveData="(data)=>saveCurData(data,dataObj,'explain')"></Richtxt>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "editDatavue",
  data() {
    return {
      optionNameList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
      labelKeypointsList: [],
      labelList: [],
      labelLoading: false,
      labelKeypointsLoading: false,
      categoryList: [],
      dataObj: {},
    }
  },
  props: {
    allData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    allCategoryList: {
      type: Array,
      default: () => {
        return []
      }
    },
    statusObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    levelObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typesList: {
      type: Array,
      default: () => {
        return []
      }
    },
    sourceFroms: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    Richtxt
  },
  watch: {
    allData: {
      handler(newV, oldV) {
        if (!oldV && newV.data.id && newV.allCategory.length) {
          this.dataObj = JSON.parse(JSON.stringify(newV.data));
          this.init();
        } else if (newV.data.id) {
          this.dataObj = JSON.parse(JSON.stringify(newV.data));
          this.init();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    init() {
      this.categoryList = JSON.parse(JSON.stringify(this.allData.allCategory));
      // console.log(this.allData,"-=-=-=-=-=-=-=-=-=");
      // console.log(this.dataObj,this.categoryList,'this.categoryList')

      this.getLabel(this.dataObj.labelKeywords, 'ids');
      this.getLabelKeypoints(this.dataObj.labelKeypoints, 'ids');

      this.categoryList.forEach((item) => {
        let len = this.checkCategoryId(item);
        if (len.length) {
          this.$set(item, 'status', true);
        } else {
          this.$set(item, 'status', false);
        }
      })
    },
    checkCategoryId(data) {
      let len = this.dataObj.categoryIds.filter((item) => {
        return item == data.value;
      })
      return len
    },
    changeCategory(data) {
      data.status = !data.status;
      if (!data.status) {
        let index = this.dataObj.categoryIds.findIndex((item) => {
          return data.value == item;
        })
        this.$delete(this.dataObj.categoryIds, index);
      } else {
        this.dataObj.categoryIds.push(data.value);
      }
      this.$emit('changeData', this.dataObj);
    },
    changeRadio(name, curData) {

      this.dataObj[name] = curData;
      if (name == 'type') {
        if (curData == 8 || curData == 9 || curData == 10) {
          this.dataObj.options = [];
        } else {
          if (!this.dataObj.options.length) {
            this.dataObj.options = [
              {
                name: '',
                is_correct: '0'
              },
              {
                name: '',
                is_correct: '0'
              },
              {
                name: '',
                is_correct: '0'
              },
              {
                name: '',
                is_correct: '0'
              },
            ];
          }

        }
      }
      this.$emit('changeData', this.dataObj);
    },
    saveCurData(curData, data, name) {
      data[name] = curData;
      this.$emit('changeData', this.dataObj);
    },
    changeCorrect(data, obj) {  //修改正确选项
      if (obj.type == 1 || obj.type == 3) { //单选
        if (data.is_correct == '0') {
          obj.options.forEach((item) => {
            item.is_correct = '0';
          });
          this.$nextTick(() => {
            data.is_correct = '1';
            this.$emit('changeData', this.dataObj);
          })
        } else {
          this.$nextTick(() => {
            data.is_correct = '0';
            this.$emit('changeData', this.dataObj);
          })
        }
      } else {
        data.is_correct == '0' ? data.is_correct = '1' : data.is_correct = '0';
        this.$emit('changeData', this.dataObj);
      }
    },
    addOption(data) {  //添加选项
      if (data.length == 12) {
        this.$Message.warning('最多可添加12项');
        return;
      }
      data.push({
        name: '',
        id: '0',
        is_correct: '0'
      })
      this.$emit('changeData', this.dataObj);
    },
    deleOption(index) {  //删除选项
      this.dataObj.options.splice(index, 1);
      this.$emit('changeData', this.dataObj);
    },
    getLabel(data, type, fn) {
      let pramas = {
        page: this.page,
        pageSize: this.pageSize,
      };

      if (type && data) {
        pramas[type] = data.join(',');
      } else {
        pramas.keyword = data;
      }

      this.labelLoading = true;
      this.api.dataset.labelList(pramas).then((res) => {
        this.labelLoading = false;

        this.labelList = res.list;

        fn && fn();
      }).catch(() => {
        this.labelLoading = false;
      })
    },
    getLabelKeypoints(data, type, fn) {
      let pramas = {
        page: this.page,
        pageSize: this.pageSize,
        type: 1,
      };

      if (type && data) {
        pramas[type] = data.join(',');
      } else {
        pramas.keyword = data;
      }

      this.labelKeypointsLoading = true;
      this.api.dataset.labelList(pramas).then((res) => {
        this.labelKeypointsLoading = false;

        this.labelKeypointsList = res.list;

        fn && fn();
      }).catch(() => {
        this.labelKeypointsLoading = false;
      })
    },
    confirmAddLabel(name) {  //确定
      let params = {
        name: name
      };
      this.addLabelLoading = true;
      this.api.dataset.labelCreate(params).then((res) => {
        this.addLabelLoading = false;

        let len = this.dataObj.labelKeywords.filter((item) => {
          return item == res.id;
        });
        if (!len.length) {
          let labelKeywords = JSON.parse(JSON.stringify(this.dataObj.labelKeywords));

          this.$delete(labelKeywords, labelKeywords.length - 1);
          labelKeywords.push(res.id);

          this.getLabel(labelKeywords, 'ids', () => {
            this.$nextTick(() => {
              this.dataObj.labelKeywords = JSON.parse(JSON.stringify(labelKeywords));
              this.$emit('changeData', this.dataObj);
            })

          });
        }
        // this.getLabel(labels,'ids');

        // this.getLabel(this.allData[this.curId].labels,'ids');

        this.addLabelModal = false;
      }).catch(() => {
        this.addLabelLoading = false;
      })
    },
    confirmAddLabelKeypoints(name) {  //确定
      let params = {
        name: name,
        type: 1,
      };
      this.addLabelKeypointsLoading = true;
      this.api.dataset.labelCreate(params).then((res) => {
        this.addLabelKeypointsLoading = false;
        // console.log(this.dataObj.labelKeypoints,'this.mainData.labelKeywordsthis.mainData.labelKeywords')


        // this.mainData.labelKeypoints = this.mainData.labelKeypoints.concat(res.id + '');
        let len = this.dataObj.labelKeypoints.filter((item) => {
          return item == res.id;
        });
        if (!len.length) {
          let labelKeypoints = JSON.parse(JSON.stringify(this.dataObj.labelKeypoints));

          this.$delete(labelKeypoints, labelKeypoints.length - 1);
          labelKeypoints.push(res.id);

          this.getLabelKeypoints(labelKeypoints, 'ids', () => {
            this.$nextTick(() => {
              this.dataObj.labelKeypoints = JSON.parse(JSON.stringify(labelKeypoints));
              this.$emit('changeData', this.dataObj);
            })

          });
        }
        this.addLabelKeypointsModal = false;
      }).catch(() => {
        this.addLabelKeypointsLoading = false;
      })
    },
    changeLabel(data) { //修改标签
      if (data.length) {
        if (!this.labelList.length) {
          //添加
          this.confirmAddLabel(data[data.length - 1]);
        } else {
          //判断是否有id
          let len = this.labelList.filter((item) => {
            return item.id == data[data.length - 1];
          })
          if (!len.length) { //添加
            this.confirmAddLabel(data[data.length - 1]);
          } else {
            this.dataObj.labelKeywords = data;
          }
        }
      } else {
        this.dataObj.labelKeywords = data;
      }
      this.dataObj.labelKeywords = data;
      this.$emit('changeData', this.dataObj);
    },
    subTitleChange(data) {
      if (data.length) {
        this.$emit('changeData', this.dataObj);
      }
    },
    changeLabelKeypoints(data) { //修改标签
      if (data.length) {
        if (!this.labelKeypointsList.length) {
          //添加
          this.confirmAddLabelKeypoints(data[data.length - 1]);
        } else {
          //判断是否有id
          let len = this.labelKeypointsList.filter((item) => {
            return item.id == data[data.length - 1];
          })
          if (!len.length) { //添加
            this.confirmAddLabelKeypoints(data[data.length - 1]);
          } else {
            this.dataObj.labelKeypoints = data;
          }
        }
      } else {
        this.dataObj.labelKeypoints = data;
      }
      this.dataObj.labelKeypoints = data;
      this.$emit('changeData', this.dataObj);
    },
    remoteMethod(query, type) { //模糊搜索
      if (type == 'node') {
        if (query !== '') {
          this.getKnowledgePoint(query);
        } else {
          this.knowledgePointList = [];
        }
      } else if (type == 'label') {
        if (query !== '') {
          this.getLabel(query);
        } else {
          this.labelList = [];
        }
      } else if (type == 'labelKeyPoint') {
        if (query !== '') {
          this.getLabelKeypoints(query);
        } else {
          this.labelKeyPointsList = [];
        }
      }

    },
  }
}
</script>

<style scoped lang="scss">
.data {
  //纠错
  //height: 500px;
  //overflow-y: auto;
  font-size: 14px;
  font-weight: 400;
  color: #333333;

  .data-cont {
    margin-bottom: 10px;
    padding: 30px 20px 30px 0;
    background-color: #FFFFFF;
  }

  .data-name,
  .tit {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .data-type-tit {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  .data-type {
    margin-bottom: 16px;
    white-space: normal;
    display: flex;
    justify-content: flex-start;

    .data-type-name {
      margin-right: 10px;
      width: 80px;
      font-weight: bold;
      color: #333333;
      text-align: right;
    }

    .data-type-list {
      flex: 1;
      width: 0;

      .data-type-list-item {
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #333333;
        cursor: pointer;

        >p {
          margin-left: 6px;
        }
      }

      .active {
        color: #4A79FF !important;
      }
    }

    .data-type-list-top {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .data-correct {
        width: 95px;
        height: 34px;
        background: #FFFFFF;
        border: 1px solid #999999;
        border-radius: 4px;
        font-weight: bold;
        text-align: center;
        line-height: 34px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .active {
        background: #54C99F;
        color: #FFFFFF !important;
        border: none;
      }

      .data-dele {
        cursor: pointer;
      }
    }

    .data-type-list-radio {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .data-type-list-radio-item {
        margin-right: 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        >img {
          margin-right: 4px;
        }
      }

      .active {
        color: #4A79FF !important;
      }
    }

  }

  .add-option-btn {
    margin-left: 90px;
    margin-bottom: 24px;
    width: 94px;
    height: 34px;
    background: #4A79FF;
    border-radius: 4px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 34px;
  }
}</style>
